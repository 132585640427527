/* Circle with Shadow */

.circle {
  width: 300px;
  /* Adjust the size of the circle */
  height: 300px;
  /* Should be the same as the width for a perfect circle */
  background-color: #fff;
  /* Background color of the circle */
  border-radius: 50%;
  /* Makes the element a perfect circle */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: black;
  /* Box shadow for the circle */
  display: flex;
  /* flex-direction: column;
   */
  align-items: center;
  justify-content: center;
}

.circle p {
  color: black;
}

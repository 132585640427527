.contactFormContent {
  /* background-color: red; */
  width: 60vw;
  height: auto;
  max-width: 60vh;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--cardColor);
}

.titleBox {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  font-weight: bold;
}

.emailBox,
.fullNameBox,
.textBox {
  color: white;
  margin-bottom: 20px;
}

.contactLabel {
  display: block;
  font-size: 16px;
  font-weight: bold;
  /* color: #333; */
  margin-bottom: 5px;
}

.emailInput,
.fullNameInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: black;
  outline: none;
}

.textInput {
  width: 100%;
  height: 20vh;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: black;
  outline: none;
  /* vertical-align: top; */
  line-height: 1.2;
  overflow-y: auto;
  resize: none;
}

.buttonBox {
  text-align: center;
}

button {
  background-color: white;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #272a2d99;
  color: white;
}

@media (max-width: 450px) {
  .contactFormContent {
    background-color: red;
    width: max-content;
    height: auto;
    max-width: 100%;
    /* margin: 0 auto; */
    padding: 20px;
    /* border: 1px solid #e0e0e0; */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--cardColor);
  }
}

/* HomePage.module.css */

.body {
  /* background-color: red; */
}

.introText {
  font-size: 3rem;
}
.name {
  font-size: 3.5rem; /* Adjust to the size you want for the name */
  font-weight: bold;
}

.role {
  font-size: 3rem; /* Adjust to the size you want for the roles */
  font-weight: bold;
}

.blinking_cursor {
  display: inline-block;
  width: 1px;
  background-color: black;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: black;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #0b192c;
  /* background-color: #0b192c; */
  /* font-family: '"Roboto", sans-serif'; */
  font-family: '"Poppins", sans-serif';
  font-size: large;
  /* font-family: 'Times New Roman', Times, serif; */
}

body {
  /* background-image: url('./images/starry-sky-background-isvz58f43n99xmrf.jpg'); */
  /* background-image: url('./images/whiteBg.jpg');
  position: sticky; */
  /* background-color: var(--backgroundColor);
  font-family: sans-serif; */
}

section {
  /* scroll-behavior: smooth; */
}

:root {
  /* --backgroundColor: #0c2d48;
  --letterColor: white;
  --cardColor: #2e8bc0;
  --buttonColor: #145da0;
  --buttonColorHover: white;
  --buttonLetters: white;
  --buttonLettersHover: #0c2d48; */
  /* --fontFamily: 'cursive'; */
}

.responsiveImage {
  width: 100%; /* Full width for smaller screens */
  max-width: 25rem; /* Restrict size on larger screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .responsiveImage {
    width: 30rem; /* Smaller width for tablets */
    height: auto;
  }
}

@media (max-width: 576px) {
  .responsiveImage {
    width: 20rem; /* Smaller width for mobile */
    height: auto;
  }
}
